export default defineNuxtRouteMiddleware((to) => {
  const { user } = useWerkgelukUser();
  const localePath = useLocalePath();

  //TODO: /mijn-account/bedrijfsgegevens should be i18n
  if (!user.value) {
    return navigateTo(
      localePath({ name: "login", query: { redirect: to.path } }),
    );
  }

  const isRegistrationComplete = user.value.registrationComplete;
  const isOnAccountCompletionPage =
    to.path === "/mijn-account/bedrijfsgegevens";

  if (!isRegistrationComplete && !isOnAccountCompletionPage) {
    return navigateTo({ path: "/mijn-account/bedrijfsgegevens" });
  }

  if (isRegistrationComplete && isOnAccountCompletionPage) {
    //It is not possible yet to create more than 1 company, so if user tries to go to /mijn-account/bedrijfsgegevens, we redirect to /mijn-account
    return navigateTo({ path: "/mijn-account" });
  }
});
